.login-container{
    display: grid;
    place-items: center;
    height: 100vh;
}

.login-form{
    border: 2px solid;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 5px 10px 10px 15px rgba(0, 0, 0, 0.2)
}

.row{
    width: 50vw;
    height: 10vh;
    text-align: center;
}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
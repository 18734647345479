.main-container{
    display: grid;
    place-items: center;
    height: 100vh;
}


.container{
    height: 80vh;
    border: 1px solid;
    border-radius: 50px;
    box-shadow: 10px 20px 15px 15px rgba(0, 0, 0, 0.2)
}


.firstCont {
    border-bottom: 2px solid;
    height: 15vh;
}

.secondCont {
    padding: 20px;
}
table{
    margin-bottom: 30px;
}
table caption{
    background-color: #999;
}